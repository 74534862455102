import { useEffect, useState } from 'react'
import { getDeploymentStatus, ReadyState } from '../services/vercel'

const useStatusReporter = () => {
  const [currentStatus, setCurrentStatus] = useState<ReadyState>()
  const [hasError, setHasError] = useState(false)
  const [intervalId, setIntervalId] = useState<any>()

  const triggerReporter = async (id: string) => {
    const {
      data: { readyState: initialState },
    } = await getDeploymentStatus(id)
    setCurrentStatus(initialState)

    setIntervalId(
      setInterval(async () => {
        try {
          const {
            data: { readyState },
          } = await getDeploymentStatus(id)

          if (readyState === ReadyState.ERROR) throw Error()

          setCurrentStatus(readyState)
        } catch (error) {
          setHasError(true)
        }
      }, 5000),
    )
  }

  useEffect(() => {
    console.log({ intervalId, hasError, currentStatus })

    if (hasError || currentStatus === ReadyState.READY) {
      clearInterval(intervalId)
    }
  }, [intervalId, hasError, currentStatus])

  return { currentStatus, triggerReporter, hasError }
}

export default useStatusReporter
