import React from 'react'
import ReactDOM from 'react-dom'
import {
  init,
  locations,
  AppExtensionSDK,
  PageExtensionSDK,
} from '@contentful/app-sdk'
import Config from './components/Config'
import VercelDeployView from './components/VercelDeploy'
import BrowserAlert from './components/BrowserAlert'

import 'antd/dist/antd.css'

if (window.self === window.top) {
  const root = document.getElementById('root')

  ReactDOM.render(<BrowserAlert />, root)
} else {
  init((sdk) => {
    const root = document.getElementById('root')

    const ComponentLocationSettings = [
      {
        location: locations.LOCATION_APP_CONFIG,
        component: <Config sdk={sdk as AppExtensionSDK} />,
      },
      {
        location: locations.LOCATION_PAGE,
        component: <VercelDeployView sdk={sdk as PageExtensionSDK} />,
      },
    ]

    ComponentLocationSettings.forEach((componentLocationSetting) => {
      if (sdk.location.is(componentLocationSetting.location)) {
        ReactDOM.render(componentLocationSetting.component, root)
      }
    })
  })
}
