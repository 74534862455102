import React from 'react'
import { Empty } from 'antd'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const BrowserAlert = () => {
  return (
    <Wrapper>
      <div className="content">
        <Empty
          image="https://d21buns5ku92am.cloudfront.net/41748/images/347873-Mark_Circular_white_no%20shadow-3d0a86-large-1582585736.png"
          description="You must visit this app from the Contentful web app!"
        />
      </div>
    </Wrapper>
  )
}

export default BrowserAlert
