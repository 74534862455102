import React, { useEffect, useState } from 'react'
import { AppExtensionSDK } from '@contentful/app-sdk'
import { Layout, Typography } from 'antd'
import styled from 'styled-components'

type AppInstallationParameters = {}

type Props = {
  sdk: AppExtensionSDK
}

const { Content } = Layout
const { Text, Title } = Typography

const Wrapper = styled.div`
  max-width: 768px;
  margin: auto;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
`

const Main = ({ sdk }: Props) => {
  const [params, setParams] = useState<AppInstallationParameters | null>()

  const onConfigure = async () => {
    const currentState = await sdk.app.getCurrentState()

    console.log({ currentState })

    return { parameters: params ?? {}, targetState: currentState }
  }

  useEffect(() => {
    sdk.app.onConfigure(onConfigure)

    const loadConfig = async () => {
      const parameters: AppInstallationParameters | null = await sdk.app.getParameters()

      setParams(parameters)
      sdk.app.setReady()
    }

    loadConfig()
  }, [])

  return (
    <Wrapper>
      <Content>
        <Title>Configuration</Title>
        <Text>With this app you can deploy your Jeili Site Vercel app</Text>
      </Content>
    </Wrapper>
  )
}

export default Main
