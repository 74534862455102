import axios, { AxiosPromise } from 'axios'
import secrets from '../config/secrets'

const fetch = axios.create({
  baseURL: secrets.vercelApiURL,
  headers: {
    Authorization: `Bearer ${secrets.vercelToken}`,
  },
})

export enum ReadyState {
  QUEUED = 'QUEUED',
  BUILDING = 'BUILDING',
  READY = 'READY',
  ERROR = 'ERROR',
}

export type VercelDeployment = {
  alias: string[]
  gitSource: {
    ref: string
    repoId: number
    type: string
    sha: string
  }
  id: string
  name: string
  readyState: ReadyState
}

export const triggerDeploy = (
  branch: string,
): AxiosPromise<VercelDeployment> => {
  return fetch('/v12/now/deployments', {
    method: 'POST',
    data: {
      name: secrets.vercelProjectName,
      files: [],
      gitSource: {
        repoId: secrets.repoId,
        ref: branch,
        type: 'github',
      },
    },
  })
}

export const getDeploymentStatus = (
  id: string,
): AxiosPromise<VercelDeployment> => {
  return fetch(`/v11/now/deployments/${id}`)
}
