import React, { useState, useEffect } from 'react'
import { PageExtensionSDK } from '@contentful/app-sdk'
import { Select, Typography, Button, Layout, Spin, message } from 'antd'
import {
  CloudUploadOutlined,
  InfoCircleOutlined,
  CodeOutlined,
  ChromeOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'
import { toCssPrefix } from '../utils'
import { ReadyState, triggerDeploy, VercelDeployment } from '../services/vercel'
import useStatusReporter from '../hooks/useStatusReporter'

type Props = {
  sdk: PageExtensionSDK
}

const { Title, Text, Link } = Typography
const { Content } = Layout
const { cssPrefix, toPrefix } = toCssPrefix('VercelDeployView__')

const Wrapper = styled.div`
  background: black;
  color: white;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .${cssPrefix} {
    &inner {
      width: 100%;
    }

    &logo {
      display: block;
      margin: 0 auto;
      object-fit: contain;
      width: 120px;
      height: 85px;
    }

    &content {
      max-width: 830px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #d9d9d9;
      margin-top: 65px;
      border-radius: 4px;
      min-height: 330px;

      .ant-spin-nested-loading {
        width: 100%;
        height: 100%;

        .ant-spin-container {
          padding: 30px;
          min-height: 330px;
          height: 100%;
        }
      }
    }

    &mr-10 {
      margin-right: 10px;
    }

    &title {
      font-size: 24px;
      text-align: center;
      display: block;
      margin-bottom: 65px;
      color: white;
    }

    &content-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: center;
    }

    &controls,
    &status {
      width: 49%;
    }

    &select {
      width: 260px;
      display: block;
      margin: 25px auto;

      .ant-select-arrow {
        color: #d9d9d9;
      }

      .ant-select-selector {
        background: transparent;
        color: #d9d9d9;
      }
    }

    &submit {
      margin: auto;
      display: block;
      width: 260px;
    }

    &status {
      margin: 25px auto 0;
      width: fit-content;

      &-state,
      &-deployment-id,
      &-domain {
        display: block;
        margin: auto;
        color: white;

        svg {
          width: 22px;
          height: 22px;
        }
      }

      &-domain,
      a {
        color: white;
      }

      a {
        text-decoration: underline;
      }

      &-progress {
        width: fit-content;
        margin-bottom: 25px;
      }
    }
  }
`

const branches = [
  {
    value: 'production',
    env: 'production',
    label: 'Production',
    domain: 'jeiliyejan.com',
  },
  {
    value: 'master',
    env: 'staging',
    label: 'Staging',
    domain: 'jeili-site.vercel.app',
  },
]

const VercelDeployView = ({ sdk }: Props) => {
  const [selectedStage, setSelectedStage] = useState<string | null>()
  const [deployment, setDeployment] = useState<VercelDeployment | null>()
  const [loading, setLoading] = useState(false)

  const { currentStatus, triggerReporter } = useStatusReporter()

  const onSubmit = async () => {
    if (!loading) {
      try {
        if (!selectedStage) {
          throw { notify: true, message: 'A branch is required' }
        }

        setLoading(true)
        const { data } = await triggerDeploy(selectedStage)

        setDeployment(data)
        triggerReporter(data.id)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (currentStatus === ReadyState.READY) {
      setLoading(false)
      setSelectedStage(null)

      message.success('Site deployed successfully')
    }
  }, [currentStatus])

  return (
    <Wrapper className={cssPrefix}>
      <div className={toPrefix('inner')}>
        <img
          className={toPrefix('logo')}
          src="https://images.ctfassets.net/qj5nmw54dgsw/3Td24KxAt45qKGRSpucOBF/50bdc71ac6a94b50a04f442eea2b638a/ISO.png"
          alt="jeili-yejan"
        />
        <Content className={toPrefix('content')}>
          <Spin
            tip="Loading..."
            className={toPrefix('spinner')}
            spinning={loading}
          >
            <Title className={toPrefix('title')}>
              Deploy Jeili Site on Vercel
            </Title>

            <div className={toPrefix('content-wrapper')}>
              <div className={toPrefix('controls')}>
                <Select
                  disabled={loading}
                  allowClear
                  value={selectedStage ?? undefined}
                  onChange={(val) => {
                    setSelectedStage(val?.toString())
                    setDeployment(null)
                  }}
                  className={toPrefix('select')}
                  placeholder="Select environment"
                  size="large"
                  options={branches.map((branch) => ({
                    label: `${branch.label} (${branch.domain})`,
                    value: branch.value,
                  }))}
                />

                <Button
                  loading={loading}
                  className={toPrefix('submit')}
                  size="large"
                  type="primary"
                  icon={<CloudUploadOutlined size={32} />}
                  disabled={
                    !selectedStage ||
                    loading ||
                    currentStatus === ReadyState.BUILDING
                  }
                  onClick={onSubmit}
                >
                  Create Deployment
                </Button>
              </div>
              {currentStatus && deployment && (
                <div className={toPrefix('status')}>
                  <Text className={toPrefix('status-state')}>
                    <InfoCircleOutlined
                      className={toPrefix('mr-10')}
                      size={42}
                    />
                    Status: {currentStatus}
                  </Text>
                  <Text className={toPrefix('status-deployment-id')}>
                    <CodeOutlined className={toPrefix('mr-10')} size={42} />
                    Deployment ID: {deployment.id}
                  </Text>
                  <Text className={toPrefix('status-domain')}>
                    <ChromeOutlined className={toPrefix('mr-10')} size={42} />
                    Domain:
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://${deployment.alias[0]}`}
                    >
                      &nbsp;{deployment.alias[0]}
                    </Link>
                  </Text>
                </div>
              )}
            </div>
          </Spin>
        </Content>
      </div>
    </Wrapper>
  )
}

export default VercelDeployView
